<template>
  <div class="row">
    <div class="col-sm-12">
      <ProductData :data="product.data" :language="language" />

      <hr />

      <h4>Product metadata</h4>
      Product metadata are internal fields used to manage the product in the
      system. These fields are never exposed to the customer. Some of these
      fields can be managed by the supplier.
      <table class="table">
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
            <th>Required</th>
            <th>Supplier editable</th>
            <th>Field info</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>id</td>
            <td>{{ product.id }}</td>
            <td>X</td>
            <td></td>
            <td>Internal id of the product in the application</td>
          </tr>
          <tr>
            <td>supplierId</td>
            <td>{{ product.supplierId }}</td>
            <td>X</td>
            <td></td>
            <td>
              SystemId of the supplier that own the product and controls
              permissions to it
            </td>
          </tr>
          <tr>
            <td>schemaVersion</td>
            <td>{{ product.schemaVersion }}</td>
            <td>X</td>
            <td></td>
            <td>
              <p>
                The format of the JSON document that this product is using.
                Information to the consuming application about which fields and
                document format to expect. Will also make it possible to migrate
                documents into new versions when future updates are made to the
                datamodel.
              </p>
              <p>
                Update to major version contain a breaking change to the format
                (for example update of an existing field). Update to minor
                version is backwards compatible to major version (for example
                addition of a new field).
              </p>
            </td>
          </tr>
          <tr>
            <td>fieldSet</td>
            <td>{{ product.fieldSet }}</td>
            <td></td>
            <td>X</td>
            <td>
              Optional, if a fieldset was used to create the product it is
              specified here. This will control which fields are visible in the
              UI when this product is edited.
            </td>
          </tr>
          <tr>
            <td>activeFromDate</td>
            <td>{{ product.activeFromDate }}</td>
            <td></td>
            <td>X</td>
            <td>
              Only products that are currently active are visible to customers.
              Products are active when current date is between ActiveFromDate
              and ActiveToDate
            </td>
          </tr>
          <tr>
            <td>activeToDate</td>
            <td>{{ product.activeToDate }}</td>
            <td></td>
            <td>X</td>
            <td>
              Only products that are currently active are visible to customers.
              Products are active when current date is between ActiveFromDate
              and ActiveToDate
            </td>
          </tr>
          <tr>
            <td>visibleTo</td>
            <td>{{ product.visibleTo }}</td>
            <td></td>
            <td>X</td>
            <td>
              Normally an <i>active</i> product is visible to all customers. But
              by specifying specific customers the product is only visible to
              these customers
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ProductData from "./ProductData.vue";

export default {
  name: "Product",
  components: {
    ProductData,
  },
  props: {
    product: {},
    language: null,
  },
};
</script>
