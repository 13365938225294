<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Variant {{ variant.variantId }}</h5>
      <div class="card-body">
        <h6>Variant fixed fields</h6>
        <table class="table">
          <thead>
            <tr>
              <th>Field</th>
              <th>Value</th>
              <th>Required</th>
              <th>Field info</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>id</td>
              <td>{{ variant.variantId }}</td>
              <td>X</td>
              <td>Usually the articlenumber</td>
            </tr>
            <tr>
              <td>name</td>
              <td>
                <FieldValue
                  fieldType="translatedText"
                  :language="language"
                  :value="variant.name"
                />
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>unitOfMeasure</td>
              <td>{{ variant.unitOfMeasure }}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>availableFromDate</td>
              <td>{{ variant.availableFromDate }}</td>
              <td></td>
              <td>Informs the customer when the variant will be available</td>
            </tr>
            <tr>
              <td>availableToDate</td>
              <td>{{ variant.availableToDate }}</td>
              <td></td>
              <td>
                Informs the customer when the variant is no longer available
              </td>
            </tr>
          </tbody>
        </table>

        <h6>Variant prices</h6>

        <p>Only required fields of the price object are used by a supplier.</p>

        <table class="table">
          <thead>
            <tr>
              <th>name</th>
              <th>price</th>
              <th>vat</th>
              <th>priceWithVat</th>
              <th>currency</th>
              <th>type</th>
            </tr>
          </thead>
          <tbody>
            <Price :price="p" v-for="p in variant.price" :key="p.name" />
          </tbody>
        </table>

        <h6>Variant dynamic fields</h6>

        <Fields :fields="variant.fields" :language="language" />

        <h6>Variant images</h6>
        <Images :images="variant.images" />
      </div>
    </div>
  </div>
</template>

<script>
import Fields from "./Fields.vue";
import FieldValue from "./FieldValue.vue";
import Images from "./Images.vue";
import Price from "./Price.vue";

export default {
  name: "Variant",
  components: {
    Fields,
    FieldValue,
    Images,
    Price,
  },
  props: {
    variant: {},
    language: null,
  },
};
</script>
