<template>
  <div class="row mt-3">
    <div class="col-6 col-lg-4 col-xxl-3">
      <h4>Product images</h4>
      <p>
        Initially only image-urls will be stored, future versions will allow
        image upload and possibly image manipulation and automatic image
        scaling.
      </p>
      <Images :images="data.images" />
    </div>
    <div class="col-6 col-lg-8 col-xxl-9">
      <h4>Product fixed fields</h4>
      The fixed fields are fields that are always set on a product, no matter
      what type of product it is. These field values are entered by the
      supplier.
      <table class="table">
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
            <th>Required</th>
            <th>Field info</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>revision</td>
            <td>{{ data.revision }}</td>
            <td>X</td>
            <td>
              Value that get incremented by 1 every time the product is modified
              making it easy to see if a printed/exported version of the product
              is the latest. The system may in the future allow storing old
              revisions of a product.
            </td>
          </tr>

          <tr>
            <td>productId</td>
            <td>{{ data.productId }}</td>
            <td>X</td>
            <td>The suppliers id for this product.</td>
          </tr>

          <tr>
            <td>name</td>
            <td>
              <FieldValue
                fieldType="translatedText"
                :language="language"
                :value="data.name"
              />
            </td>
            <td>X</td>
            <td></td>
          </tr>

          <tr>
            <td>brand</td>
            <td>{{ data.brand }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>group</td>
            <td>{{ data.group }}</td>
            <td></td>
            <td>
              The group or family of products that this product belongs to
            </td>
          </tr>
          <tr>
            <td>availableFromDate</td>
            <td>{{ data.availableFromDate }}</td>
            <td></td>
            <td>Informs the customer when the product will be available</td>
          </tr>
          <tr>
            <td>availableToDate</td>
            <td>{{ data.availableToDate }}</td>
            <td></td>
            <td>
              Informs the customer when the product is no longer available
            </td>
          </tr>
          <tr>
            <td>tags</td>
            <td>{{ data.tags }}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <h4>Product dynamic fields</h4>
      <p>
        The dynamic fields are available to suppliers and may be added to
        products. Fields used will be different between product types and
        suppliers, the system will suggest appropriate fields to use depending
        on product type. These field values are entered by the supplier.
      </p>
      <p>
        The system contains a selected set of fields, only these fields will be
        available to add data to. This will make the entity data standardized to
        customers that download data from multiple suppliers.
      </p>

      <Fields :fields="data.fields" :language="language" />

      <h4>Product variants</h4>
      <p>
        Variants are the SKUs or stocked items. If the product is a shirt with a
        brand and material description. Then a variant of that shirt is that
        shirt in a specific size and color.
      </p>

      <div v-for="variant in data.variants" :key="variant.variantId">
        <variant :variant="variant" :language="language" />
      </div>
    </div>
  </div>
</template>

<script>
import Fields from "./Fields.vue";
import FieldValue from "./FieldValue.vue";
import Images from "./Images.vue";
import Variant from "./Variant.vue";

export default {
  name: "ProductData",
  components: {
    Fields,
    FieldValue,
    Variant,
    Images,
  },
  props: {
    data: {},
    language: null,
  },
};
</script>
