<template>
  <div class="card-columns">
    <div
      v-for="image in images"
      :key="image.type"
      class="card mb-2 m-2"
      style="width: 18rem; float:left;"
    >
      <img class="card-img-top" :src="image.url" alt="Card image cap" />
      <div class="card-body">
        <h5 class="card-title">{{ image.name }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">
          ImageType: {{ image.imageType }}
        </h6>
        <p class="card-text">
          {{ image.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Images",
  props: {
    images: [],
  },
};
</script>
