<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>FieldId</th>
          <th>Type</th>
          <th>Value</th>
          <th>Info</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="field in fields" :key="field.id">
          <td>{{ field.fieldId }}</td>
          <td>{{ field.type }}</td>
          <td>
            <FieldValue
              :fieldType="field.type"
              :language="language"
              :value="field.value"
            />
          </td>
          <td>{{ fieldInfo[field.fieldId] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import FieldValue from "./FieldValue.vue";

export default {
  name: "Fields",
  components: {
    FieldValue,
  },
  data: function () {
    return {
      fieldInfo: {
        baseColorId:
          "Basecolor in the system, making it possible to filter/find products by color independent of supplier name for the specific color.",
        colorId:
          "The ID that the supplier use for a specific color in their system used to order the item",
        colorDescription:
          "The name that the supplier use for a specific color in their system (description connected to ColorId field)",
      },
    };
  },
  props: {
    fields: [],
    language: null,
  },
};
</script>
