<template>
  <tr>
    <td>{{ price.name }}</td>
    <td>{{ price.price }}</td>
    <td>{{ price.vat }}</td>
    <td>{{ price.priceWithVat }}</td>
    <td>{{ price.currency }}</td>
    <td>{{ price.type }}</td>
  </tr>
</template>

<script>
export default {
  name: "Price",
  props: {
    price: {},
  },
};
</script>
