<template>
  <span> {{ getValue() }} </span>
</template>

<script>
export default {
  methods: {
    getValue: function () {
      if (this.fieldType === "translatedText") {
        var translation = this.value[this.language];
        if (!translation) {
          translation = `Translation not found in value for language '${
            this.language
          }', raw: '${JSON.stringify(this.value)}'`;
        }
        return translation;
      } else if (this.fieldType === "price") {
        // Sample: { "SEK": { "price": 350.2, "VAT": 0.25, "priceWithVat": 437.75 } }

        for (const property in this.value) {
          var price = this.value[property];
          return `${price.price} ${property} (${price.priceWithVat} with ${price.VAT} VAT)`;
        }
      } else {
        return this.value;
      }
    },
  },
  name: "FieldValue",
  props: {
    fieldType: null,
    value: null,
    language: null,
  },
};
</script>
