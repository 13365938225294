<template>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h5 class="mt-3">Select product:</h5>
        <div class="btn-group" role="group">
          <button
            v-for="product in products"
            :key="product.id"
            v-bind:class="{
              'btn btn-primary': product.id === activeProduct.id,
              'btn btn-secondary': product.id !== activeProduct.id,
            }"
            v-on:click="selectProduct(product)"
          >
            <span v-if="product.data && product.data.name">
              {{ product.data.name[activeLanguage] }}
            </span>
            <span v-else>{{ product.id }}</span>
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <h5 class="mt-3">Select language:</h5>
        <div class="btn-group" role="group">
          <button
            v-for="language in languages"
            :key="language"
            v-bind:class="{
              'btn btn-primary': language === activeLanguage,
              'btn btn-secondary': language !== activeLanguage,
            }"
            v-on:click="setLanguage(language)"
          >
            {{ language }}
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <h5 class="mt-3">View productdata:</h5>
        <button class="btn btn-warning" v-on:click="saveFile()">
          Open productdata JSON
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <hr />
        <product
          v-if="activeProduct && Object.keys(activeProduct).length > 0"
          :product="activeProduct"
          :language="activeLanguage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Product from "./Product.vue";

export default {
  name: "ProductList",
  components: {
    Product,
  },
  data: function () {
    return {
      languages: ["en-US", "sv-SE"],
      activeLanguage: "en-US",
      activeProduct: {},
    };
  },
  props: {
    msg: String,
    products: [],
  },
  methods: {
    selectProduct: function (product) {
      this.activeProduct = product;
    },
    setLanguage: function (language) {
      this.activeLanguage = language;
    },
    saveFile: function () {
      const data = JSON.stringify(this.products);
      const blob = new Blob([data], { type: "text/plain" });
      var url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
      // window.location.href = url;
    },
  },
  beforeMount() {
    if (this.products && this.products.length > 0) {
      this.selectProduct(this.products[0]);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
