<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <ProductList msg="Product Info" :products="products" />
  </div>
</template>

<script>
import ProductList from "./components/ProductList.vue";
import productData from "./assets/products.json";

export default {
  name: "App",
  components: {
    ProductList,
  },
  data() {
    return {
      products: productData,
    };
  },
};
</script>

<style>

</style>
